import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { MdOpenInNew } from "react-icons/md"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"

const ChiSonoPage = () => {
  return <Layout>
    <Seo
      title="Chi sono"
      description="Sono il Dott. Francesco Latini, psicologo psicoterapeuta iscritto all'Albo A dell'Ordine degli Psicologi della Lombardia (OPL). La mia formazione si è svolta prima sui banchi dell'Università di Milano-Bicocca (UNIMIB) ottenendo la laurea cum laude, proseguendo poi in un Master biennale ..."
    />
    <Hero title="Chi sono" />
    <section className="grid grid-cols-6 py-6">
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
        <p>Sono il Dott. Francesco Latini, <b>psicologo psicoterapeuta</b> iscritto all'<b>Albo A</b> dell'<b>Ordine degli Psicologi della Lombardia</b> (<a href="https://www.opl.it/">OPL</a>).</p>
        <p className="mt-3">La mia formazione si è svolta prima sui banchi dell'<b>Università di Milano-Bicocca</b> (<a href="https://www.unimib.it/aree-disciplinari/psicologica">UNIMIB</a>) ottenendo la laurea <i>cum laude</i>, proseguendo poi con un <b>Master biennale sulla Psicodiagnosi</b> presso lo Studio Associato <a href="https://www.arpmilano.it/">ARP</a> ed un <b>Master executive</b> sulla <b>Clinica della relazione di Coppia</b> con <a href="https://www.psicologilombardia.it/">APL</a>. Ho infine conseguito <i>cum laude</i> il titolo di Psicoterapeuta Psicodinamico presso la Scuola di Psicoterapia Psicoanalitica di Milano (<a href="https://www.sppscuoladipsicoterapia.it/corsi-milano/eta-adulta/organizzazione.html">SPP</a>)</p>
        <p className="mt-3">I miei <b>tirocini professionalizzanti</b> si sono svolti presso il <a href="https://carceredibollate.it/">Carcere di Bollate</a> e nelle Comunità Terapeutiche a Media Protezione (CPM) della <a href="https://www.fondazionelighea.it/">Fondazione Lighea Onlus</a> e nel <a href="https://www.asst-santipaolocarlo.it/centro-giovani-ponti">Centro Giovani Ettore Ponti</a> dell'ASST Santi Paolo e Carlo di Milano.</p>
        <p className="mt-3">Ho collaborato per diversi anni con la <a href="https://www.coopcalypso.it/">Cooperativa Calypso</a> nel campo del <b>disagio psichico grave</b>. Attualmente, oltre al lavoro in <b>studio privato</b> e <b>online</b>, collaboro anche con la <a href="https://www.lemacchinecelibi.coop/">Cooperativa Le Macchine Celibi</a> come <b>supervisore</b> presso un centro di aggregazione giovanile.</p>
      </article>
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0 mt-6">
        <div className="flex md:justify-around flex-col md:flex-row">
          <a
            href="https://elencopsicologi.it/Francesco-Latini/?q=8631"
            className="text-center self-center mb-4 md:m-0"
            rel="noreferrer"
            target="_blank"
          >
            <StaticImage
              src="../../assets/social-icons/elencopsicologi.png"
              alt="Elenco Psicologi"
              height={72}
            />
            <div className="flex items-center">
              <span className="pr-2">Elenco Psicologi</span>
              <MdOpenInNew />
            </div>
          </a>
          <a
            href="https://www.psymap.it/scheda.php?idp=3313&origLat=&origLon="
            className="text-center self-center mb-4 md:m-0"
            rel="noreferrer"
            target="_blank"
          >
            <StaticImage
              src="../../assets/social-icons/psymap.png"
              alt="Psymap"
              height={72}
            />
            <div className="flex items-center">
              <span className="pr-2">Psymap</span>
              <MdOpenInNew />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/in/francesco-latini-680075108"
            className="text-center self-center"
            rel="noreferrer"
            target="_blank"
          >
            <StaticImage
              src="../../assets/social-icons/linkedin.png"
              alt="LinkedIn"
              height={72}
            />
            <div className="flex items-center">
              <span className="pr-2">LinkedIn</span>
              <MdOpenInNew />
            </div>
          </a>
        </div>
      </article>
    </section>
  </Layout>;
}

export default ChiSonoPage
